.edgebutton {
    width: 50px;
    height: 50px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    color: black;
    border-radius: 50%;
    font-size: 40px;
    line-height: 1;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }